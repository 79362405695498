import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import ViewEmailModal from 'components/ViewEmailModal';

import { getTouchPreview } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({

}));

interface Props {
  onClose: () => void;
  touchTrigger: any;
}

const PreviewEmailModal = ({ onClose, touchTrigger }: Props) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext() as any;

  const touchName = `${touchTrigger.description.split(' - ')[1]}`; // Description form is 'Campaign ID - Email #1'

  const [emailData, setEmailData] = React.useState<{ subject: string; html: string; } | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        const data = await getTouchPreview(touchTrigger._id);
        setEmailData(data);
      } catch (err) {
        console.error('error', err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, []);

  return (
    <ViewEmailModal
      title={`${touchName} Preview`}
      emailSubject={emailData?.subject}
      emailHtml={emailData?.html}
      onClose={onClose}
    />
  );
};

export default PreviewEmailModal;
