import styles from 'assets/jss/material-dashboard-pro-react/creative-components/adminNavbarStyle';
import cx from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
// material-ui icons
import Menu from '@material-ui/icons/Menu';

// core components
import Button from 'creative-components/CustomButtons/Button';

const useStyles = makeStyles(styles);

export default function AdminNavbar({
  color, brandText, handleDrawerToggle, sidebarMinimize, miniActive,
}) {
  const classes = useStyles();
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        {/* <Hidden smDown implementation="css">
          <div className={classes.sidebarMinimize}>
            {miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden> */}
        {/* <div className={classes.flex}>
          <h3>{brandText}</h3>
          <br />
        </div> */}
        {/* <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden> */}
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
