import axios from 'axios';
import log from 'loglevel';

import { clearAuthToken, getAuthToken, LOGIN_ROUTE } from 'components/AuthDataProvider/AuthDataProvider';

const noAuthRedirectHeader = 'x-no-auth-redirect';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiClient.interceptors.request.use((request) => {
  const authToken = getAuthToken();

  if (authToken) request.headers.Authorization = `Bearer ${getAuthToken()}`;

  return request;
});

apiClient.interceptors.response.use((response) => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    // Authentication error, redirect to login page
    clearAuthToken();
    if (window.location.pathname !== LOGIN_ROUTE) {
      if (!error.config.headers[noAuthRedirectHeader]) {
        window.location.href = LOGIN_ROUTE;
      }
    }
  }

  return Promise.reject(error);
});

const authenticate = async (email: string, password: string, passwordResetToken: string | null = null) => {
  const response = await apiClient.post('/authentication/authenticate', {
    email,
    password,
    passwordResetToken,
  });

  const { token, expiresInDays } = response.data;

  return { token, expiresInDays };
};

const authenticateAssumeUser = async (userId: string) => {
  const response = await apiClient.post(`/authentication/${userId}/assume-user`);

  const { token, expiresInDays } = response.data;

  return { token, expiresInDays };
};

const getEventTypes = async (includePrice = false) => {
  const response = await apiClient.get('/event-types', { params: { includePrice } });

  log.debug('called getEventTypes and got', response.data);

  return response.data;
};

const getRoles = async () => {
  const response = await apiClient.get('/roles');

  log.debug('called getRoles and got', response.data);

  return response.data;
};

const getInterestApplications = async () => {
  const response = await apiClient.get('/interest-applications');

  log.debug('called getInterestApplications and got', response.data);

  return response.data;
};

// const submitInterestApplication = async (firstName, lastName, email, phoneNumber, region, zipcodesInterestedIn, eventInterestedIn, propertyTypesInterestedIn) => {
const submitInterestApplication = async (firstName: string, lastName: string, email: string, phoneNumber: string, region: string, simpleFlow: boolean, referral?: string):
Promise<{ interestApplication: string, passwordResetUrl: string }> => {
  const response = await apiClient.post(
    '/interest-applications',
    {
      firstName,
      lastName,
      email,
      phoneNumber,
      region,
      simpleFlow,
      referral,
      // zipcodesInterestedIn,
      // eventInterestedIn,
      // propertyTypesInterestedIn,
    },
  );

  log.debug('called submitInterestApplication and got', response.data);

  return response.data;
};

const approveOrRejectInterestApplication = async (applicationId: string, status: string, rejectionReason: string | null = null, sendRejectionEmail: string | null = null) => {
  const response = await apiClient.put(
    `/interest-applications/${applicationId}`,
    {
      status,
      rejectionReason: rejectionReason || undefined,
      sendRejectionEmail: sendRejectionEmail !== undefined ? sendRejectionEmail : undefined,
    },
  );

  log.debug('called approveOrRejectInterestApplication and got', response.data);

  return response.data;
};

const submitBrokerageInterest = async (data: any) => {
  const response = await apiClient.post('/interest-applications/brokerage', data);

  log.debug('called submitBrokerageInterest and got', response.data);

  return response.data;
};

const passwordReset = async (email: string) => {
  const response = await apiClient.post(
    '/users/password-reset',
    {
      email,
    },
  );

  log.debug('called passwordReset and got', response.data);

  return response.data;
};

const lookupPasswordResetToken = async (passwordResetToken: string) => {
  const response = await apiClient.get(`/users/password-reset/${passwordResetToken}`);

  log.debug('called lookupPasswordResetToken and got', response.data);

  return response.data;
};

const createUser = async (email: string, firstName: string, lastName: string, phoneNumber: string, roleId: string) => {
  const response = await apiClient.post(
    '/users',
    {
      email, firstName, lastName, phoneNumber, roleId,
    },
  );

  log.debug('called createUser and got', response.data);

  return response.data;
};

const updateUser = async (data: any) => {
  const response = await apiClient.put('/users', data);

  log.debug('called updateUser and got', response.data);

  return response.data;
};

const updateUserById = async (userId: string, data: any) => {
  const response = await apiClient.put(`/users/${userId}`, data);

  log.debug('called updateUserById and got', response.data);

  return response.data;
};

const subscribeCrmPro = async (data: any) => {
  const response = await apiClient.post('/users/subscribe-crm-pro', data);

  log.debug('called subscribeCrmPro and got', response.data);

  return response.data;
};

const unsubscribeCrmPro = async (userId: string, data: any) => {
  const response = await apiClient.post(`/users/${userId}/unsubscribe-crm-pro`, data);

  log.debug('called unsubscribeCrmPro and got', response.data);

  return response.data;
};

const getUserInvoices = async () => {
  const response = await apiClient.get('/users/invoices');

  log.debug('called getUserInvoices and got', response.data);

  return response.data;
};

const getUserPaymentMethodSetupIntent = async () => {
  const response = await apiClient.get('/users/payment-method/setup-intent');

  log.debug('called getUserPaymentMethodSetupIntent and got', response.data);

  return response.data;
};

const updateUserPaymentMethod = async (paymentMethodId: string) => {
  const response = await apiClient.put(
    '/users/payment-method',
    {
      paymentMethodId,
    },
  );

  log.debug('called updateUserPaymentMethod and got', response.data);

  return response.data;
};

const getAgentCustomers = async () => {
  const response = await apiClient.get('/agent-customers');

  log.debug('called getAgentCustomers and got', response.data);

  return response.data;
};

const getAgentCustomersCount = async () => {
  const response = await apiClient.get('/agent-customers/count');

  log.debug('called getAgentCustomersCount and got', response.data);

  return response.data;
};

// const createAgentCustomer = async (primaryContact, secondaryContact, notes, customerType) => {
const createAgentCustomer = async (primaryContact: any, secondaryContact: any, notes: string) => {
  const response = await apiClient.post(
    '/agent-customers',
    {
      primaryContact, secondaryContact, notes,
      // customerType,
    },
  );

  log.debug('called createAgentCustomer and got', response.data);

  return response.data;
};

// const updateAgentCustomer = async (agentCustomerId, primaryContact, secondaryContact, notes, customerType) => {
const updateAgentCustomer = async (agentCustomerId: string, primaryContact: any, secondaryContact: any, notes: string) => {
  const response = await apiClient.put(
    `/agent-customers/${agentCustomerId}`,
    {
      primaryContact, secondaryContact, notes,
      // customerType,
    },
  );

  log.debug('called updateAgentCustomer and got', response.data);

  return response.data;
};

const deleteAgentCustomers = async (agentCustomerIds: string[]) => {
  const response = await apiClient.delete('/agent-customers', { params: { agentCustomerIds } });

  log.debug('called deleteAgentCustomers and got', response.data);

  return response.data;
};

const getUserInfo = async () => {
  const response = await apiClient.get('/users', { headers: { [noAuthRedirectHeader]: true } });

  log.debug('called getUserInfo and got', response.data);

  return response.data;
};

const getAgentCustomerImports = async () => {
  const response = await apiClient.get('/agent-customer-imports');

  log.debug('called getAgentCustomerImports and got', response.data);

  return response.data;
};

const importAgentCustomers = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await apiClient.post(
    '/agent-customer-imports',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  log.debug('called importAgentCustomers and got', response.data);

  return response.data;
};

const getEventData = async (eventTypeName: string) => {
  const response = await apiClient.get('/event-data', { params: { eventTypeName } });

  log.debug('called getEventData and got', response.data);

  return response.data;
};

const getAvailabilityCountsForZipcodes = async (eventTypeId: string, zipcodes: string[]) => {
  const response = await apiClient.get('/event-data/availability-counts', { params: { eventTypeId, zipcodes } });

  log.debug('called getAvailabilityCountsForZipcodes and got', response.data);

  return response.data;
};

const getAgentUsers = async () => {
  const response = await apiClient.get('/users/agents');

  log.debug('called getAgentUsers and got', response.data);

  return response.data;
};

const exportCampaign = async (campaignId: string) => {
  const response = await apiClient.get(`/campaigns/${campaignId}/export`);

  log.debug('called exportCampaign and got', response.data);

  return response.data;
};

const getCampaignCancellationFee = async (campaignId: string) => {
  const response = await apiClient.get(`/campaigns/${campaignId}/cancellation-fee`);

  log.debug('called getCampaignCancellationFee and got', response.data);

  return response.data;
};

const cancelCampaign = async (campaignId: string, waiveCancellationFee: boolean) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/cancel`, {
    waiveCancellationFee,
  });

  log.debug('called cancelCampaign and got', response.data);

  return response.data;
};

const createAutoRenewalCampaign = async (campaignId: string) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/auto-renewal`);

  log.debug('called createAutoRenewalCampaign and got', response.data);

  return response.data;
};

const pauseCampaign = async (campaignId: string) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/pause`);

  log.debug('called pauseCampaign and got', response.data);

  return response.data;
};

const resumeCampaign = async (campaignId: string) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/resume`);

  log.debug('called resumeCampaign and got', response.data);

  return response.data;
};

const updateCampaign = async (campaignId: string, data: any) => {
  const response = await apiClient.put(`/campaigns/${campaignId}`, data);

  log.debug('called updateCampaign and got', response.data);

  return response.data;
};

const resendDocuSignEmail = async (campaignId: string) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/resend-docusign-email`);

  log.debug('called resendDocuSignEmail and got', response.data);

  return response.data;
};

const resendSignUpEmail = async (userId: string) => {
  const response = await apiClient.post(`/users/${userId}/resend-sign-up-email`);

  log.debug('called resendSignUpEmail and got', response.data);

  return response.data;
};

const updateCampaignEventOverride = async (campaignId: string, eventId: string, newOwnerContact: any, farmingStopped: boolean, notes: string) => {
  const response = await apiClient.put(
    `/campaigns/${campaignId}/event-overrides/${eventId}`,
    {
      newOwnerContact,
      farmingStopped,
      notes,
    },
  );

  log.debug('called updateCampaignEventOverride and got', response.data);

  return response.data;
};

const getTouchPreview = async (touchTriggerId: string, args?: { sendEmailPreview?: boolean }): Promise<any> => {
  const response = await apiClient.get(`/touch-triggers/${touchTriggerId}/preview`, { params: args });

  log.debug('called getTouchPreview and got', response.data);

  return response.data;
};

const createCustomTouchTemplate = async (touchTriggerId: string,
  file: any | undefined = undefined, customBodyText: string | undefined = undefined,
  useQRCodeOnUploadedPdf: boolean | undefined = undefined, customEmailSubject: string | undefined = undefined) => {
  const formData = new FormData();

  if (file !== undefined) formData.append('file', file);
  if (customBodyText !== undefined) formData.append('customBodyText', customBodyText);
  if (customEmailSubject !== undefined) formData.append('customEmailSubject', customEmailSubject);
  if (useQRCodeOnUploadedPdf !== undefined) formData.append('useQRCodeOnUploadedPdf', `${useQRCodeOnUploadedPdf}`);

  const response = await apiClient.post(
    `/touch-triggers/${touchTriggerId}/custom-touch-template`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  log.debug('called createCustomTouchTemplate and got', response.data);

  return response.data;
};

const resetCustomTouchTemplate = async (touchTriggerId: string) => {
  const response = await apiClient.delete(`/touch-triggers/${touchTriggerId}/custom-touch-template`);

  log.debug('called resetCustomTouchTemplate and got', response.data);

  return response.data;
};

const delayTouchSendDate = async (touchTriggerId: string, numberOfDays: number) => {
  const response = await apiClient.post(`/touch-triggers/${touchTriggerId}/delay`, { numberOfDays });

  log.debug('called delayTouchSendDate and got', response.data);

  return response.data;
};

const getUserInfoById = async (userId: string) => {
  const response = await apiClient.get(`/users/${userId}`);

  log.debug('called getUserInfoById and got', response.data);

  return response.data;
};

const deleteUser = async (userId: string) => {
  const response = await apiClient.delete(`/users/${userId}`);

  log.debug('called deleteUser and got', response.data);

  return response.data;
};

const checkCampaignAddOnProperties = async (addOnPropertiesJsonString: string) => {
  const response = await apiClient.post('/campaigns/check-add-on-properties', { addOnPropertiesJsonString });

  log.debug('called saveCampaignDraft and got', response.data);

  return response.data;
};

const saveCampaignDraft = async (data: any) => {
  const response = await apiClient.post('/campaigns/draft', data);

  log.debug('called saveCampaignDraft and got', response.data);

  return response.data;
};

const deleteCampaign = async (campaignId: string) => {
  const response = await apiClient.delete(`/campaigns/${campaignId}`);

  log.debug('called deleteCampaign and got', response.data);

  return response.data;
};

const createCampaign = async (campaignId: string) => {
  const response = await apiClient.post(`/campaigns/${campaignId}/create`);

  log.debug('called createCampaign and got', response.data);

  return response.data;
};

/* const getZipcodesByBounds = async (cancellationSource, boundsNe, boundsSw, fetchEventDataType) => {
  log.debug('calling getZipcodesByBounds...');
  const response = await api.post('/zipcodes/bounds', {
    boundsNe,
    boundsSw,
    fetchEventDataType,
  },
  { cancelToken: cancellationSource.token });

  log.debug('called getZipcodesByBounds and got', response.data);

  return response.data;
}; */

const getZipcodesByBounds = async (boundsNe: { lat: number; lng: number; }, boundsSw: { lat: number; lng: number }) => {
  log.debug('calling getZipcodesByBounds...');
  const response = await apiClient.post('/zipcodes/bounds', {
    boundsNe,
    boundsSw,
  });

  log.debug('called getZipcodesByBounds and got', response.data);

  return response.data;
};

/* const getZipcodeEventDataCounts = async (zipcode, fetchEventDataTypes) => {
  log.debug('calling getZipcodeEventDataCounts...');
  const response = await api.post(`/zipcodes/${zipcode}/event-data-counts`, {
    fetchEventDataTypes,
  });

  log.debug('called getZipcodeEventDataCounts and got', response.data);

  return response.data;
}; */

const getDynamicDataCount = async (eventTypeId: string, propertyFilters: string[], polygonPaths: any, zipcode: string) => {
  log.debug('calling getDynamicDataCount...');
  const response = await apiClient.post('/dynamic-data/count', {
    eventTypeId,
    polygonPaths,
    zipcode,
    propertyFilters,
  });

  log.debug('called getDynamicDataCount and got', response.data);

  return response.data;
};

export const getSimpleCampaignCounts = async (zipcode: string): Promise<{ apnsTotal: number, apnsWithPropensityToSell: number }> => {
  log.debug('calling getSimpleCampaignCounts...');
  const response = await apiClient.post('/simple-campaign/counts', {
    zipcode,
  });

  log.debug('called getSimpleCampaignCounts and got', response.data);

  return response.data;
};

export const createSimpleCampaign = async (zipcode: string, numApns: number): Promise<void> => {
  log.debug('calling createSimpleCampaign...');
  const response = await apiClient.post('/simple-campaign/create', {
    zipcode,
    numApns,
  });

  log.debug('called createSimpleCampaign and got', response.data);

  return response.data;
};

const getCRMProCurrentYearTouchTriggers = async () => {
  const response = await apiClient.get('/touch-triggers/crm-pro-current-year');

  log.debug('called getCRMProCurrentYearTouchTriggers and got', response.data);

  return response.data;
};

const getFeedbackTopics = async () => {
  const response = await apiClient.get('/feedback/topics');

  log.debug('called getFeedbackTopics and got', response.data);

  return response.data;
};

const getQuestionsForFeedbackTopicType = async (type: string) => {
  const response = await apiClient.post('/feedback/questions', {
    type,
  });

  log.debug('called getQuestionsForFeedbackTopicType and got', response.data);

  return response.data;
};

const submitFeedback = async (type: string, topicName: string, metadata: any, questions: any, responses: string[]) => {
  const response = await apiClient.post('/feedback', {
    type,
    topicName,
    metadata,
    questions,
    responses,
  });

  log.debug('called submitFeedback and got', response.data);

  return response.data;
};

const newsletterSubscribe = async (data: any) => {
  const response = await apiClient.post('/newsletter/subscribe', data);

  log.debug('called newsletterSubscribe and got', response.data);

  return response.data;
};

const newsletterUnsubscribe = async (data: any) => {
  const response = await apiClient.get('/newsletter/unsubscribe', { params: data });

  log.debug('called unsubscribeToken and got', response.data);

  return response.data;
};

const getAgentLandingPageData = async (agentLandingPageToken: string) => {
  const response = await apiClient.post('/agent-landing/page-data', { token: agentLandingPageToken });

  log.debug('called getAgentLandingPageData and got', response.data);

  return response.data;
};

const getAgentLandingHomeEstimationData = async (agentLandingPageToken: string) => {
  const response = await apiClient.post('/agent-landing/home-estimation-data', { token: agentLandingPageToken });

  log.debug('called getAgentLandingHomeEstimationData and got', response.data);

  return response.data;
};

const getAgentLandingSampleData = async (agentLandingPageToken: string, eventId: string | null) => {
  const response = await apiClient.post('/agent-landing/sample-data', { token: agentLandingPageToken, eventId });

  log.debug('called getAgentLandingSampleData and got', response.data);

  return response.data;
};

const submitAgentLandingContactAgentForm = async (agentLandingPageToken: string, fullName: string, phoneNumber: string, email: string, message: string, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/contact-agent-form', {
    token: agentLandingPageToken,
    fullName,
    phoneNumber,
    email,
    message,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingContactAgentForm and got', response.data);

  return response.data;
};

const submitAgentLandingEmailAgentForm = async (agentLandingPageToken: string, email: string, subject: string, message: string, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/email-agent-form', {
    token: agentLandingPageToken,
    email,
    subject,
    message,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingEmailAgentForm and got', response.data);

  return response.data;
};

const submitAgentLandingPriceTooLowForm = async (agentLandingPageToken: string, phoneNumber: string, email: string, estimateOffAmount: string, wantProfessionalOpinion: boolean, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/price-too-low-form', {
    token: agentLandingPageToken,
    phoneNumber,
    email,
    estimateOffAmount,
    wantProfessionalOpinion,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingPriceTooLowForm and got', response.data);

  return response.data;
};

const submitAgentLandingSellForThisPriceForm = async (agentLandingPageToken: string, phoneNumber: string, email: string, howSoonToSell: string, wantProfessionalOpinion: boolean, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/sell-for-this-price-form', {
    token: agentLandingPageToken,
    phoneNumber,
    email,
    howSoonToSell,
    wantProfessionalOpinion,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingSellForThisPriceForm and got', response.data);

  return response.data;
};

const submitAgentLandingAssessmentForm = async (agentLandingPageToken: string, interests: string[], phoneNumber: string, email: string, message: string, bestDateToCall: string, bestTimeToCall: string, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/assessment-form', {
    token: agentLandingPageToken,
    interests,
    phoneNumber,
    email,
    message,
    bestDateToCall,
    bestTimeToCall,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingAssessmentForm and got', response.data);

  return response.data;
};

const submitAgentLandingConsentForm = async (agentLandingPageToken: string, phoneNumber: string, email: string, recaptchaValue: string) => {
  const response = await apiClient.post('/agent-landing/consent-form', {
    token: agentLandingPageToken,
    phoneNumber,
    email,
    recaptchaValue,
  });

  log.debug('called submitAgentLandingConsentForm and got', response.data);

  return response.data;
};

const downloadReportAgentLanding = async (agentLandingPageToken: string, homeEstimateMapImage: any, similarHomesTableImage: any, eventId: string | null) => {
  const response = await apiClient.post('/agent-landing/download-report', {
    token: agentLandingPageToken,
    homeEstimateMapImage,
    similarHomesTableImage,
    eventId,
  });

  log.debug('called downloadReportAgentLanding and got', response.data);

  return response.data;
};

const getIntercomUserHash = async () => {
  const response = await apiClient.get('/intercom/user-hash');

  log.debug('called getIntercomUserHash and got', response.data);

  return response.data;
};

const getVoicemails = async () => {
  const response = await apiClient.get('/voicemails');

  log.debug('called getVoicemails and got', response.data);

  return response.data;
};

const createVoicemail = async (name: string, recordingData: any) => {
  const response = await apiClient.post('/voicemails', {
    name,
    recordingData,
  });

  log.debug('called createVoicemail and got', response.data);

  return response.data;
};

const updateVoicemail = async (voicemailId: string, data: any) => {
  const response = await apiClient.put(`/voicemails/${voicemailId}`, data);

  log.debug('called updateVoicemail and got', response.data);

  return response.data;
};

const updateTouchVoicemail = async (touchTriggerId: string, data: any) => {
  const response = await apiClient.post(`/touch-triggers/${touchTriggerId}/voicemail`, data);

  log.debug('called updateTouchVoicemail and got', response.data);

  return response.data;
};

const createBrokerage = async (name: string, email: string, firstName: string, lastName: string, phoneNumber: string, leadType: string) => {
  const response = await apiClient.post('/brokerages', {
    name, email, firstName, lastName, phoneNumber, leadType,
  });

  log.debug('called createBrokerage and got', response.data);

  return response.data;
};

const getBrokerage = async () => {
  const response = await apiClient.get('/brokerages/me');

  log.debug('called getBrokerage and got', response.data);

  return response.data;
};

const addBrokerageAdmin = async (email: string, firstName: string, lastName: string, phoneNumber: string) => {
  const response = await apiClient.post('/brokerages/admins', {
    email, firstName, lastName, phoneNumber,
  });

  log.debug('called addBrokerageAdmin and got', response.data);

  return response.data;
};

const addBrokerageAgent = async (brokerageBranchId: string, email: string, firstName: string, lastName: string, phoneNumber: string) => {
  const response = await apiClient.post('/brokerages/agents', {
    brokerageBranchId, email, firstName, lastName, phoneNumber,
  });

  log.debug('called addBrokerageAgent and got', response.data);

  return response.data;
};

const updateBrokerageName = async (name: string) => {
  const response = await apiClient.put('/brokerages/name', {
    name,
  });

  log.debug('called updateBrokerageName and got', response.data);

  return response.data;
};

const updateBrokerageBranchMarketingInfo = async (data: any) => {
  const response = await apiClient.put('/brokerages/branches', data);

  log.debug('called updateBrokerageBranchMarketingInfo and got', response.data);

  return response.data;
};

const assignAgentBranches = async (brokerageBranchId: string, data: any) => {
  const response = await apiClient.put(`/brokerages/branches/${brokerageBranchId}/agents`, data);

  log.debug('called assignAgentBranches and got', response.data);

  return response.data;
};

const getBrokeragePaymentMethodSetupIntent = async () => {
  const response = await apiClient.get('/brokerages/payment-method/setup-intent');

  log.debug('called getBrokeragePaymentMethodSetupIntent and got', response.data);

  return response.data;
};

const updateBrokeragePaymentMethod = async (paymentMethodId: string) => {
  const response = await apiClient.put(
    '/brokerages/payment-method',
    {
      paymentMethodId,
    },
  );

  log.debug('called updateBrokeragePaymentMethod and got', response.data);

  return response.data;
};

const unsubscribeEmailTouch = async (unsubscribeToken: string) => {
  const response = await apiClient.put('/unsubscribe/email-touch', {
    unsubscribeToken,
  });

  log.debug('called unsubscribeEmailTouch and got', response.data);

  return response.data;
};

const getSettings = async (entityType: string, settingType: string) => {
  const response = await apiClient.get('/settings', { params: { entityType, settingType } });

  log.debug('called getSettings and got', response.data);

  return response.data;
};

export {
  apiClient,
  authenticate,
  authenticateAssumeUser,
  getEventTypes,
  getRoles,
  getInterestApplications,
  submitInterestApplication,
  approveOrRejectInterestApplication,
  submitBrokerageInterest,
  passwordReset,
  lookupPasswordResetToken,
  createUser,
  updateUser,
  updateUserById,
  subscribeCrmPro,
  unsubscribeCrmPro,
  getUserInvoices,
  getUserPaymentMethodSetupIntent,
  updateUserPaymentMethod,
  getAgentCustomers,
  getAgentCustomersCount,
  createAgentCustomer,
  updateAgentCustomer,
  deleteAgentCustomers,
  getAgentCustomerImports,
  importAgentCustomers,
  getUserInfo,
  getEventData,
  getAvailabilityCountsForZipcodes,
  getAgentUsers,
  createAutoRenewalCampaign,
  exportCampaign,
  resendDocuSignEmail,
  resendSignUpEmail,
  checkCampaignAddOnProperties,
  saveCampaignDraft,
  deleteCampaign,
  createCampaign,
  updateCampaignEventOverride,
  getCampaignCancellationFee,
  cancelCampaign,
  pauseCampaign,
  resumeCampaign,
  updateCampaign,
  getUserInfoById,
  deleteUser,
  getTouchPreview,
  createCustomTouchTemplate,
  resetCustomTouchTemplate,
  delayTouchSendDate,
  getZipcodesByBounds,
  getDynamicDataCount,
  getCRMProCurrentYearTouchTriggers,
  getFeedbackTopics,
  getQuestionsForFeedbackTopicType,
  submitFeedback,
  newsletterSubscribe,
  newsletterUnsubscribe,
  getAgentLandingPageData,
  getAgentLandingHomeEstimationData,
  getAgentLandingSampleData,
  submitAgentLandingContactAgentForm,
  submitAgentLandingEmailAgentForm,
  submitAgentLandingPriceTooLowForm,
  submitAgentLandingSellForThisPriceForm,
  submitAgentLandingAssessmentForm,
  submitAgentLandingConsentForm,
  downloadReportAgentLanding,
  getIntercomUserHash,
  getVoicemails,
  createVoicemail,
  updateVoicemail,
  updateTouchVoicemail,
  createBrokerage,
  getBrokerage,
  addBrokerageAdmin,
  addBrokerageAgent,
  updateBrokerageName,
  updateBrokerageBranchMarketingInfo,
  assignAgentBranches,
  getBrokeragePaymentMethodSetupIntent,
  updateBrokeragePaymentMethod,
  unsubscribeEmailTouch,
  getSettings,
};
