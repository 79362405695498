import { backdropContainer, backdropImage } from 'assets/jss/material-dashboard-pro-react';
import { useAdCampaignDetail } from 'data/ad-campaign';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EAdCampaignStatus } from 'types/ad-campaign';

import {
  ButtonBase, Chip, Grid, Tooltip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from 'creative-components/CustomButtons/Button';

import ActionModal from 'components/ActionModal/ActionModal';
import AdCampaignLeadsTable from 'components/AdCampaignLeadsTable';
import AdPreview from 'components/AdPreview';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { ADS_DASHBOARD_ROUTE, useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import MarketingProfileIncompleteModal from 'components/CampaignSteps/MarketingProfileIncompleteModal';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { updateAdCampaign } from 'utils/api/ad-campaigns';
import {
  enumToReadableName, getAgentLandingPageSampleUrlFromType, numberWithCommas, showAPIErrorAlert,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  backdropContainer,
  backdropImage,
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,

    '& h4': {
      fontWeight: 'bold',
      marginBottom: '4px',
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.black.main,
    },
  },

  instructionsBubble: {
    margin: '47px auto 32px',

    '& > button': { // "Click here"
      font: 'inherit',
      verticalAlign: 'top', // Line up with text
      textDecoration: 'underline',
      color: theme.palette.orange.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },

  infoContainer: {
    marginTop: '20px',
    '& > div': {
      marginBottom: '30px',
    },
  },

  adminInfo: {
    padding: '32px 16px',
    fontWeight: 400,
    fontSize: '14px',
  },

  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
  linkClicks: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginLeft: '5px',
      color: theme.palette.orange.main,
      textDecoration: 'underline',
    },
  },
}));

interface RouteParams {
  adCampaignId: string;
}

export default () => {
  const { adCampaignId } = useParams<RouteParams>();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const {
    isAdmin, isBrokerageAdmin, isAnyAgent,
  } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext() as any; // TODO: TEMP AS ANY
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any; // TODO: TEMP AS ANY

  if (!adCampaignId) {
    // Invalid url params. Redirect to dashboard
    history.replace(ADS_DASHBOARD_ROUTE);
  }

  const {
    adCampaign, isLoadingAdCampaign, isErrorAdCampaign, mutateAdCampaign,
  } = useAdCampaignDetail(adCampaignId);

  const [showConfirmation, setShowConfirmation] = React.useState<React.ReactNode | null>(null);

  const [showMarketingProfileIncompleteModal, setShowMarketingProfileIncompleteModal] = React.useState(false);

  React.useEffect(() => {
    if (isErrorAdCampaign) {
      // Likely caused by an invalid campaign id in the url params. Redirect to dashboard
      history.push(ADS_DASHBOARD_ROUTE);
      console.error(isErrorAdCampaign);
    }
  }, [isErrorAdCampaign]);

  React.useEffect(() => {
    if (!adCampaign) return;

    setShowMarketingProfileIncompleteModal(adCampaign.status === EAdCampaignStatus.PendingMarketingProfile);
  }, [adCampaign]);

  const onUpdateAdCampaignName = async (name: string) => {
    showLoadingIndicatorModal();
    try {
      await updateAdCampaign(adCampaignId, { name });

      mutateAdCampaign({
        data: {
          ...adCampaign,
          name,
        },
      }, { revalidate: false });

      setCurrentAlert('success', 'Ad name updated');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onConfirmedPauseOrResume = async () => {
    const newStatus = [EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active].includes(adCampaign.status)
      ? EAdCampaignStatus.Paused
      : EAdCampaignStatus.Active;

    showLoadingIndicatorModal();
    try {
      await updateAdCampaign(adCampaignId, { status: newStatus });

      mutateAdCampaign();

      if (newStatus === EAdCampaignStatus.Active) {
        setCurrentAlert('success', 'Your digital ad has been resumed!');
      } else {
        setCurrentAlert('success', 'Your digital ad has been paused. You may resume it at any time to continue showing it to your audience!');
      }
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    } finally {
      hideLoadingIndicatorModal();
      setShowConfirmation(null);
    }
  };

  const onPauseOrResume = async () => {
    setShowConfirmation(<ActionModal
      backgroundColor={theme.palette.offWhite.main}
      textColor={theme.palette.darkGray.main}
      icon={<LibraryAddCheckRoundedIcon />}
      onClose={() => setShowConfirmation(null)}
      title={[EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active].includes(adCampaign.status) ? 'Pause My Ad' : 'Resume My Ad'}
      message={
        [EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active].includes(adCampaign.status)
          ? 'Are you sure you want to pause your ad? Your ad will run until it uses up its current monthly budget and then will stop showing to your audience.'
          : 'Your monthly subscription will restart today and your ad will start showing to your audience again.'
        }
      buttons={[
        <ButtonBase onClick={() => setShowConfirmation(null)} className={classes.cancelButton}>Cancel</ButtonBase>,
        <ButtonBase onClick={() => onConfirmedPauseOrResume()}>
          {[EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active].includes(adCampaign.status) ? 'Pause Ad' : 'Resume Ad'}
        </ButtonBase>,
      ]}
    />);
  };

  if (isLoadingAdCampaign) {
    return (
      <div className={classes.root}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {showConfirmation}

      <DashboardSection
        breadcrumbs={[{ name: 'Digital Ads', to: (location.state as any)?.previousPathname ?? ADS_DASHBOARD_ROUTE }]}
        sectionName={adCampaign.name}
        sectionNameEditable={isAnyAgent}
        onSectionEditName={onUpdateAdCampaignName}
        buttonLayout="right"
      />

      {(isAdmin || isBrokerageAdmin) && (
        <div className={classes.adminInfo}>
          <b>Agent: </b>
          {`${adCampaign.user.firstName} ${adCampaign.user.lastName} (${adCampaign.user._id})`}
        </div>
      )}

      {adCampaign.status === EAdCampaignStatus.PendingBilling && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content="We are awaiting payment before starting your Digital Ad."
        />
      )}

      {/* For pending marketing profile state */}
      {showMarketingProfileIncompleteModal && (
        <MarketingProfileIncompleteModal
          onClose={() => setShowMarketingProfileIncompleteModal(false)}
          message="You must complete your marketing profile before we can start showing your digital ad to your target audience!"
        />
      )}

      {adCampaign.status === EAdCampaignStatus.PendingAdApproval && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content="Your ad is pending review by our Ad Networks and will start delivering to your target audience once approved!"
        />
      )}

      {adCampaign.status === EAdCampaignStatus.Paused && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content="This ad has been paused. Resume it to start showing your ad to your target audience!"
        />
      )}

      <Grid container justifyContent="flex-start" className={classes.infoContainer}>
        <Grid item xs={12} md={4}>
          <h4>Status</h4>
          {enumToReadableName(adCampaign.status)}
        </Grid>

        <Grid item xs={12} md={4}>
          <h4>Ad Audience</h4>
          {adCampaign.zipCodes.map((zipCode) => (
            <Chip
              key={zipCode}
              // color="primary"
              color="secondary"
              label={`Zip Code ${zipCode}`}
              style={{ margin: '5px', fontWeight: 400 }}
            />
          ))}
          {adCampaign.customAudienceCampaigns.map((campaign) => (
            <Chip
              key={campaign._id}
              color="secondary"
              label={`Campaign ${campaign.name} (${campaign.numSubscribedApns} APNs)`}
              style={{ margin: '5px', fontWeight: 400 }}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <h4>Monthly Budget</h4>
          <div>{`$${numberWithCommas((adCampaign.costs.userMonthlyBudget / 100).toFixed(2))}/mo`}</div>
          <div>{adCampaign.costs.nextInvoiceDate && `Renews on ${moment(adCampaign.costs.nextInvoiceDate).format('MMM D')}`}</div>
        </Grid>

        <Grid item xs={12} md={4}>
          <h4>Reach</h4>
          <Tooltip title="The number of unique accounts that saw your ads at least once." TransitionComponent={React.Fragment}>
            <span>{adCampaign.stats.reach ? numberWithCommas(adCampaign.stats.reach) : 'N/A'}</span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <h4>Impressions</h4>
          <Tooltip title="The number of times your ads were on screen." TransitionComponent={React.Fragment}>
            <span>{adCampaign.stats.impressions ? numberWithCommas(adCampaign.stats.impressions) : 'N/A'}</span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <h4 className={classes.linkClicks}>
            Link Clicks
            <a href={adCampaign.useCustomCallToActionLink ? adCampaign.callToActionLink : getAgentLandingPageSampleUrlFromType(adCampaign.agentLandingPageType!)} target="_blank" rel="noreferrer">
              <OpenInNewIcon />
            </a>
          </h4>
          <Tooltip title="The number of call-to-action button link clicks." TransitionComponent={React.Fragment}>
            <span>{adCampaign.stats.inlineLinkClicks ? numberWithCommas(adCampaign.stats.inlineLinkClicks) : 'N/A'}</span>
          </Tooltip>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <h4>Ad Preview</h4>
          <AdPreview imageData={adCampaign.latestImagePreviewUrl} />
        </Grid>

        {!adCampaign.useCustomCallToActionLink && (
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <DashboardSection sectionName="Your Digital Ad's Leads" />
            <AdCampaignLeadsTable adCampaignId={adCampaign._id} />
          </Grid>
        )}
      </Grid>

      {isAnyAgent && (
        <div>
          {([EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active, EAdCampaignStatus.Paused].includes(adCampaign.status)) && (
            <Button
              style={{ marginTop: '130px' }}
              round
              color="primary"
              onClick={() => onPauseOrResume()}
            >
              {[EAdCampaignStatus.PendingMarketingProfile, EAdCampaignStatus.Active].includes(adCampaign.status) ? 'Pause Ad' : 'Resume Ad'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
