import soundAnimation from 'assets/img/sound.gif';
import cx from 'classnames';
import log from 'loglevel';
import MicRecorder from 'mic-recorder-to-mp3';
import moment from 'moment';
import { Mp3MediaRecorder } from 'mp3-mediarecorder';
import React from 'react';
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import mp3RecorderWorker from 'workerize-loader!./worker';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import EditIcon from 'components/CustomIcons/EditIcon';
import TrashIcon from 'components/CustomIcons/TrashIcon';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import VoicemailScriptsModal from 'components/VoicemailScriptsModal/VoicemailScriptsModal';

import { createVoicemail, getVoicemails, updateVoicemail } from 'utils/api';
import { filterEventType, isFullFarmEventType, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    flex: 1,
    width: '100%',
    maxWidth: '850px',
  },
  bubbleMessage: {
    margin: '15px auto 47px',

    '& > p': {
      fontWeight: 500,

      '& button': {
        font: 'inherit',
        verticalAlign: 'inherit', // Line up with text
        textDecoration: 'inherit',
        color: 'inherit',

        '&:hover': {
          color: 'inherit',
        },
      },
    },
  },
  divider: {
    width: '100%',
    borderBottom: `3px solid ${theme.palette.grayScale10.main}`,
  },
  introMessageDivider: {
    margin: '47px 0 43px',
  },

  voicemails: {
    width: '100%',
    overflowX: 'auto',
  },
  voicemailRowContainer: {
    width: '100%',
    display: 'inline-block',
  },
  voicemailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > h4': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',
      color: theme.palette.grayScale11.main,
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      marginRight: '40px',

      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
  },
  voicemailRowRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  voicemailRowDivider: {
    borderBottom: '1px solid #D3D3D3',
    margin: '15px 0',
  },
  nameInputForm: {
    marginRight: '40px',

    '& > div': {
      margin: 0,
      minWidth: '200px',
    },
    '& svg': {
      // Do here since htmlColor prop doesn't work on the end adornment
      color: `${theme.palette.success.main}`,
    },
  },
  actions: {
    display: 'flex',
    margin: '0 26px 0',
    '& > span': {
      marginLeft: '17px',
    },
  },
  createNewAudioContainer: {
    marginTop: '31px',
    width: '100%',
    textAlign: 'right',

    '& > img': {
      width: '32px',
      height: '32px',
      marginRight: '20px',
    },
  },
}));

const micRecorder = new MicRecorder({
  bitRate: 128,
  encodeAfterRecord: true, // NOTE: To fix weird crackle issues on mobile
});

const startMediaRecorder = (recorderRef, worker, onDoneRecording) => new Promise((resolve, reject) => {
  if (navigator.mediaDevices.getUserMedia && window.MediaRecorder) {
    window.navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      // Set this after we get permissions
      const recordingStartTime = new Date();

      const recorder = new Mp3MediaRecorder(stream, { worker: worker.current });
      // eslint-disable-next-line no-param-reassign
      recorderRef.current = recorder;
      recorder.ondataavailable = (event) => {
        log.debug('recorder ondataavailable', event.data);

        // Convert audio blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(event.data);
        reader.onloadend = () => {
          const base64String = reader.result;
          // log.debug(base64String);
          onDoneRecording(base64String, recordingStartTime);
        };
      };
      recorder.onstart = () => {
        log.debug('recorder onstart');
      };
      recorder.onstop = () => {
        log.debug('recorder onstop');
      };

      recorder.start();
      resolve(true);
    }).catch((err) => { // Usually from microphone permission denied
      reject(err);
    });
  } else {
    log.debug('getUserMedia or MediaRecorder not supported on the browser!');
    resolve(false);
  }
});

const Voicemails = () => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { availableEventTypes } = useDataFilterContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [voicemails, setVoicemails] = React.useState(null);

  const worker = React.useRef(null);
  React.useEffect(() => {
    worker.current = mp3RecorderWorker();
  }, []);

  const [scriptsEventType, setScriptsEventType] = React.useState(null);
  const [isScriptsModalOpen, setIsScriptsModalOpen] = React.useState(false);

  const inputRef = React.useRef(null);
  const [isEditingName, setIsEditingName] = React.useState(null); // Voicemail id being archived
  const [isConfirmArchiveOpen, setIsConfirmArchiveOpen] = React.useState(null); // Voicemail id being archived

  const recorderRef = React.useRef(null);
  const [isRecording, setIsRecording] = React.useState(false);
  const [oldApiRecordingStartTime, setOldApiRecordingStartTime] = React.useState(null); // Only used on the fallback recording method

  React.useEffect(() => {
    (async () => {
      if (!availableEventTypes) return;

      try {
        const data = await getVoicemails();
        setVoicemails(data.voicemails);

        const fullFarmEventType = filterEventType(availableEventTypes, isFullFarmEventType);
        setScriptsEventType(fullFarmEventType);
      } catch (err) {
        console.error(err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, [availableEventTypes]);

  const onDoneRecording = async (recordingData, recordingStartTime) => {
    // Make sure the recording is at least 5 seconds long
    if (moment(recordingStartTime).add(5, 'seconds').isAfter(moment())) {
      setCurrentAlert('warning', 'Voicemail recordings must be at least 5 seconds long. Please try recording again.');
      return;
    }

    showLoadingIndicatorModal();

    try {
      const { voicemail } = await createVoicemail(`Untitled - ${moment().format('MM/DD/YY')}`, recordingData);

      setVoicemails([
        ...voicemails,
        voicemail,
      ]);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onToggleRecording = async () => {
    try {
      if (!isRecording) {
        if (!(await startMediaRecorder(recorderRef, worker, onDoneRecording))) {
          // Fallback to old library if MediaRecorder is not supported in the browser
          log.debug('fallback to mic-recorder-to-mp3 lib for recording');
          micRecorder.start();
          setOldApiRecordingStartTime(new Date());
        }
      } else if (recorderRef.current) {
        recorderRef.current.stop();
        recorderRef.current.stream.getAudioTracks().forEach((track) => track.stop()); // Do this to stop the microphone in use icon

        log.debug('recorder stopped');
      } else {
        micRecorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            log.debug('mic recorder for mic-recorder-to-mp3 stop called');

            // Convert audio blob to base64
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
              const base64String = reader.result;
              // log.debug(base64String);
              onDoneRecording(base64String, oldApiRecordingStartTime);
            };
          })
          .catch((e) => {
            console.error(e);
          });
      }

      setIsRecording(!isRecording);
    } catch (err) {
      console.log('Voicemail recording err', err);
      if (err.message?.toLowerCase().includes('permission denied')) {
        setCurrentAlert('warning', 'Please allow microphone permissions for this site.');
      }
    }
  };

  const onUpdateName = async (voicemailId) => {
    const name = inputRef.current.value;

    if (name.length === 0) {
      setCurrentAlert('warning', 'Please provide a name for this recording');
      return;
    }

    // Was there no name change?
    if (voicemails.find(({ _id }) => _id === voicemailId).name === name) {
      setIsEditingName(false);
      return;
    }

    showLoadingIndicatorModal();
    try {
      const { voicemail } = await updateVoicemail(voicemailId, { name });

      // Reinsert at same index
      const index = voicemails.findIndex(({ _id }) => _id === voicemailId);
      const newVoicemails = [...voicemails];
      newVoicemails.splice(index, 1, voicemail);

      setVoicemails(newVoicemails);

      setIsEditingName(false);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onArchive = async (voicemailId) => {
    showLoadingIndicatorModal();
    try {
      await updateVoicemail(voicemailId, { archived: true });

      setVoicemails(voicemails.filter(({ _id }) => _id !== voicemailId));

      setIsConfirmArchiveOpen(null);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  return (
    <div className={classes.root}>
      {isScriptsModalOpen && (
        <VoicemailScriptsModal
          eventTypeName={scriptsEventType.name}
          scripts={scriptsEventType.voicemailTexts}
          onClose={() => setIsScriptsModalOpen(false)}
          isRecording={isRecording}
          onToggleRecording={onToggleRecording}
        />
      )}

      {isConfirmArchiveOpen && (
        <ActionModal
          icon={<WarningRoundedIcon />}
          onClose={() => setIsConfirmArchiveOpen(null)}
          title="Are You Sure?"
          message="This voicemail will still be sent out with any existing campaign steps that it is associated with unless a different voicemail is selected for the campaign step."
          buttons={[<ButtonBase onClick={() => { onArchive(isConfirmArchiveOpen); }}>Confirm</ButtonBase>]}
        />
      )}

      <DashboardBubble
        rootClass={classes.bubbleMessage}
        backgroundColor="#DEF2E9"
        content={(
          <>
            <strong>Let's START!</strong>
            <br />
            <br />
            We require a few recordings which will be used to leave a voicemail for the homeowners in your chosen farm area. Your client\'s phone will ring one time then your message will, in most cases, be delivered directly to their voicemail box.
            <br />
            <br />
            Studies suggest that voicemails should be concise, friendly, and direct to the individual. We suggest you keep your voicemail recording between 15-30 seconds. After recording, listen to the playback to ensure it sounds clear and natural.
            <br />
            <br />
            <Button
              color="info"
              round
              onClick={() => setIsScriptsModalOpen(true)}
            >
              View Sample Scripts
            </Button>
          </>
        )}
      />

      <div className={cx(classes.divider, classes.introMessageDivider)} />
      {voicemails ? (
        <>
          <div className={classes.voicemails}>
            {voicemails.map(({ _id, name, recordingUrl }) => (
              <div key={_id} className={classes.voicemailRowContainer}>
                <div className={classes.voicemailRow}>
                  {isEditingName === _id ? (
                    <div className={classes.nameInputForm}>
                      <CustomInputDashboard
                        inputProps={{
                          inputRef,
                          autoFocus: true,
                          defaultValue: name,
                          minLength: 1,
                          maxLength: 32,
                          endAdornment: (
                            <ButtonBase onClick={() => onUpdateName(_id)}>
                              <DoneRoundedIcon />
                            </ButtonBase>
                          ),
                          onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                              onUpdateName(_id);
                            } else if (event.key === 'Escape') {
                              setIsEditingName(false);
                            }
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <h4>{name}</h4>
                  )}
                  <div className={classes.voicemailRowRight}>
                    <audio
                      // NOTE: Safari shows "Error" when src is set to '', so don't pass in a src if the recording is missing, pass undefined instead
                      src={recordingUrl}
                      type="audio/mpeg"
                      controls
                      preload="auto"
                    />
                    <div className={classes.actions}>
                      <Tooltip
                        title="Edit Name"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <ButtonBase onClick={() => setIsEditingName(_id)}><EditIcon color="#838391" /></ButtonBase>
                      </Tooltip>
                      <span />
                      <Tooltip
                        title="Archive"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <ButtonBase onClick={() => setIsConfirmArchiveOpen(_id)}><TrashIcon color="#838391" /></ButtonBase>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={classes.voicemailRowDivider} />
              </div>
            ))}
          </div>
          <div className={classes.createNewAudioContainer}>
            {isRecording && <img src={soundAnimation} alt="Recording" className={classes.soundAnimation} />}
            <Button
              color="primary"
              round
              onClick={onToggleRecording}
            >
              {isRecording ? 'Stop Recording' : '+ Create New Audio'}
            </Button>
          </div>
        </>
      ) : (<LoadingIndicator />)}
    </div>
  );
};

export default Voicemails;
