import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonBase, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import SubtractIcon from 'components/CustomIcons/SubtractIcon';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      width: '50%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  /* NOTE: Prefix must be same as event type name w/o spaces */
  AbsenteeOwnersButton: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.absenteeOwners.main)}, 0.2)`,
    border: `2px solid ${theme.palette.absenteeOwners.main}`,
    '& svg': {
      color: theme.palette.absenteeOwners.main,
    },
  },
  NODButton: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.nod.main)}, 0.2)`,
    border: `2px solid ${theme.palette.nod.main}`,
    '& svg': {
      color: theme.palette.nod.main,
    },
  },
  CommercialButton: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.commercial.main)}, 0.2)`,
    border: `2px solid ${theme.palette.commercial.main}`,
    '& svg': {
      color: theme.palette.commercial.main,
    },
  },
  FullFarmButton: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.fullFarm.main)}, 0.2)`,
    border: `2px solid ${theme.palette.fullFarm.main}`,
    '& svg': {
      color: theme.palette.fullFarm.main,
    },
  },

  button: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    margin: '9px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 0px 1px rgba(26, 26, 26, 0.24), 0px 4px 8px -2px rgba(32, 37, 50, 0.2)',

    '&:hover': {
      boxShadow: '0px 0px 0px 1px rgba(26, 26, 26, 0.24), 0px 4px 8px -2px rgba(32, 37, 50, 0.4)',
    },
  },
  buttonTop: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',

    '& > h4': {
      marginLeft: '10px',
      flex: 1,
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      color: theme.palette.black.main,
    },
  },
  buttonLine: {
    width: '100%',
    maxWidth: '175px',

    margin: '12px 32px',
    borderBottom: `1px solid ${theme.palette.black.main}`,
  },
  buttonBottom: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.darkGray.main,
  },
}));

const SelectCampaignEventTypeModal = ({ onClose }) => {
  const { user } = useAuthDataContext();
  const classes = useStyles();
  const history = useHistory();
  const { availableEventTypes } = useDataFilterContext();

  const usersToEnableNODsFor = [
    '668db58a1a287d5bfc2ea743', // Claudia Torrijos
  ];

  let showNODs = false;
  if (usersToEnableNODsFor.includes(user.id)) {
    showNODs = true;
  }

  const onSelect = (eventType) => {
    history.push({ pathname: '/dashboard/create-campaign', state: { eventType } });
  };

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="sm"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Select Campaign" />

      {availableEventTypes ? (
        <div className={classes.cards}>
          {/* NOTE: Disabled Commercial campaigns on 10/10/2022 and NOD campaigns on 05/21/2024 */}
          {availableEventTypes.map((eventType, i) => {
            if (eventType.name === 'Commercial') return null;
            if (eventType.name === 'NOD' && !showNODs) return null;

            return (
              <div key={i}>
                <ButtonBase onClick={() => onSelect(eventType)} className={cx(classes.button, classes[`${eventType.name.replaceAll(' ', '')}Button`])}>
                  <div className={classes.buttonTop}>
                    <SubtractIcon />
                    <h4>{eventType.name}</h4>
                  </div>
                  <div className={classes.buttonLine} />
                  <div className={classes.buttonBottom}>
                    {`${eventType.campaignLengthInMonths} months / ${eventType.numberOfTouchesPerEvent}-touches`}
                  </div>
                </ButtonBase>
              </div>
            );
          })}
        </div>
      ) : <LoadingIndicator />}
    </Dialog>
  );
};

export default SelectCampaignEventTypeModal;
