import React from 'react';
import { CreateAdCampaignDto } from 'types/ad-campaign';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';

import CreateAdCampaignView from 'views/Dashboard/ad-campaigns/CreateAdCampaignView';

type Props = {
  handleNext: () => void;
  setCreateAdCampaignData: React.Dispatch<React.SetStateAction<CreateAdCampaignDto | undefined>>;
};

export default ({
  handleNext, setCreateAdCampaignData,
}: Props) => {
  const { setCurrentAlert } = useAlertContext() as any;
  const { user, isAnyAgent } = useAuthDataContext();

  const onSimpleViewSubmit = (data: CreateAdCampaignDto) => {
    if (user && !isAnyAgent) {
      setCurrentAlert('warning', 'You can not proceed on a non-agent account.', 15000);
      return;
    }

    setCreateAdCampaignData(data);

    handleNext();
  };

  return (
    <CreateAdCampaignView onSimpleViewSubmit={onSimpleViewSubmit} />
  );
};
