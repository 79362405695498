import soundAnimation from 'assets/img/sound.gif';
import React from 'react';

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

const useStyles = makeStyles((theme) => ({
  scripts: {
    margin: '0 0 60px',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '30px',
    color: theme.palette.grayScale9.main,
  },

  startRecordingContainer: {
    marginTop: '31px',
    width: '100%',
    textAlign: 'center',

    '& > img': {
      width: '32px',
      height: '32px',
      marginRight: '20px',
    },
  },
}));

const VoicemailScriptsModal = ({
  eventTypeName, scripts, onClose, isRecording, onToggleRecording,
}) => {
  const classes = useStyles();

  const onToggleRecordingWrapper = () => {
    if (isRecording) {
      onToggleRecording();

      // Close the modal after finishing the recording
      onClose();
    } else {
      onToggleRecording();
    }
  };

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="lg"
      onClose={onClose}
    >
      {/* <SimpleModalHeader onClose={onClose} title={`${eventTypeName} Sample Scripts`} /> */}
      <SimpleModalHeader onClose={onClose} title="Sample Scripts" />

      {scripts.map((script, i) => (
        <p key={i} className={classes.scripts}>{script}</p>
      ))}

      <div className={classes.startRecordingContainer}>
        {isRecording && <img src={soundAnimation} alt="Recording" className={classes.soundAnimation} />}
        <Button
          color="primary"
          round
          onClick={onToggleRecordingWrapper}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </Button>
      </div>
    </Dialog>
  );
};

export default VoicemailScriptsModal;
