import React from 'react';
import { useHistory } from 'react-router-dom';
import { CreateAdCampaignDto } from 'types/ad-campaign';

import {
  Step, StepContent, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { ADS_DASHBOARD_ROUTE } from 'components/AuthDataProvider/AuthDataProvider';
import SimpleCreateAdCampaignStep from 'components/SimpleLaunches/SimpleAdCampaign/SimpleCreateAdCampaignStep';
import SimpleCreateAccountStep from 'components/SimpleLaunches/SimpleCreateAccountStep';

import analyticsClient from 'utils/analytics';
import { createAdCampaign } from 'utils/api/ad-campaigns';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '24px 30px 12px',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },

    '@media (min-width: 600px)': {
      width: '580px', // 12 34px-width columns with 14px gutters
    },
    '@media (min-width: 900px)': {
      width: '840px', // 12 46px-width columns with 24px gutters
    },
    '@media (min-width: 1200px)': {
      width: '1104px', // 12 60px-width columns with 32px gutters
    },
    '@media (min-width: 1440px)': {
      width: '1344px', // 12 80px-width columns with 32px gutters (including on ends, so 12 * 80 + 12 * 32)
    },
  },
  stepLabel: {
    fontSize: '18px',
  },
  stepDescription: {
    marginBottom: '20px',
  },
  stepContainer: {

  },
  map: {
    minHeight: '95vh',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px',
  },
  goToDashboardButton: {
    marginTop: '20px',
    float: 'right',
  },
}));

const steps: { [key: string]: string } = {
  'Setup Your Digital Ad': '',
  'Create account': 'Review details required for your Harvist account',
  'Digital Ad Created': 'Your ad has been created! Pending ad network approval, your ad will start running soon.',
};

const SimpleCreateCampaignPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [createAdCampaignData, setCreateAdCampaignData] = React.useState<CreateAdCampaignDto | undefined>();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  React.useEffect(() => {
    // Track GTM event
    analyticsClient.track('simple_digital_ad_creation_started');
  }, []);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {Object.keys(steps).map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
            <StepContent>
              <Typography className={classes.stepDescription}>{steps[label]}</Typography>
              <div className={classes.stepContainer}>
                {activeStep === 0 && (
                  <SimpleCreateAdCampaignStep
                    handleNext={handleNext}
                    setCreateAdCampaignData={setCreateAdCampaignData}
                  />
                )}

                {activeStep === 1 && (
                  <SimpleCreateAccountStep
                    submitButtonText="Start Ad"
                    handleNext={handleNext}
                    onLaunch={async () => {
                      await createAdCampaign(createAdCampaignData!);

                      // Track GTM event
                      await analyticsClient.track('simple_ad_campaign_creation_completed');
                    }}
                  />
                )}

                {activeStep === 2 && (
                  <Button
                    round
                    color="primary"
                    className={classes.goToDashboardButton}
                    onClick={() => history.push(ADS_DASHBOARD_ROUTE)}
                  >
                    Go to My Dashboard
                  </Button>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default SimpleCreateCampaignPage;
