import {
  Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {
  ButtonBase,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Update';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { delayTouchSendDate } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  modalMessage: {
    fontSize: '15px',
  },
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
}));

interface FormValues {
  numberOfDays: number;
}

interface Props {
  touchTrigger: any;
  onReceiveEditedTouchTrigger: any;
  onClose: any;
}

const DelayTouchModal = ({ touchTrigger, onReceiveEditedTouchTrigger, onClose }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext() as any;
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any;

  return (
    <Formik<FormValues>
      initialValues={{
        numberOfDays: 3,
      }}
      onSubmit={async (values) => {
        const { numberOfDays } = values;
        showLoadingIndicatorModal();

        try {
          const { touchTrigger: updatedTouchTrigger } = await delayTouchSendDate(touchTrigger._id, numberOfDays);

          // Callback to update the touch trigger locally
          onReceiveEditedTouchTrigger(updatedTouchTrigger);

          onClose();

          setCurrentAlert('success', `Your touch send date has been delayed by ${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'}`);
        } catch (err) {
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        } finally {
          hideLoadingIndicatorModal();
        }
      }}
      validationSchema={Yup.object().shape({
        numberOfDays: Yup.number().integer().min(1).max(3)
          .required(),
      })}
    >
      {({
        isSubmitting, setFieldValue, values, handleSubmit,
      }: FormikProps<FormValues>) => (
        <Form>
          <FormikOnError>
            <ActionModal
              backgroundColor={theme.palette.offWhite.main}
              textColor={theme.palette.darkGray.main}
              icon={<UpdateIcon />}
              onClose={onClose}
              title="Delay Send Date"
              message={(
                <div className={classes.modalMessage}>
                  You can delay the day this touch goes out by up to three days to give yourself more time to edit this touch or to change the day of the week it goes out on.
                  <br />
                  <br />
                  <br />
                  <Field name="numberOfDays">
                    {({ field, meta }: FieldProps) => (
                      <CustomSelect
                        labelText="Number of Days to Delay"
                        items={[
                          { name: '1 Day', value: 1 },
                          { name: '2 Days', value: 2 },
                          { name: '3 Days', value: 3 },
                        ]}
                        inputProps={{
                          ...field,
                        }}
                        error={meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </div>
              )}
              buttons={[
                <ButtonBase onClick={onClose} className={classes.cancelButton}>Cancel</ButtonBase>,
                <ButtonBase onClick={() => handleSubmit()} disabled={isSubmitting}>
                  Delay Touch
                </ButtonBase>,
              ]}
            />
          </FormikOnError>
        </Form>
      )}
    </Formik>
  );
};

export default DelayTouchModal;
