/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import logo from 'assets/img/logo-light.png';
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/creative-components/sidebarStyle';
import cx from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import {
  Button, ButtonBase, Popper, useMediaQuery, useTheme,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';

import UserSettingsPopUp from 'creative-components/Sidebar//UserSettingsPopUp';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { UserInfoContext, useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      className, user, headerLinks, links,
    } = this.props;

    return (
      <div className={className} ref={this.sidebarWrapper}>
        {headerLinks}
        {links}
        <div style={{ flex: 1 }} />
        {user}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  mainPanel = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };

    this.settingsPopperAnchorEl = null;
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: prop.collapseAlwaysOpen ? true : this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    // Special handling of the index dashboard route since it has route name ''
    if (!routeName) {
      return window.location.href.endsWith('/dashboard');
    }

    return window.location.href.endsWith(routeName) ? 'active' : '';
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, userRole } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      // Don't render pages which are hidden from the sidebar
      if (prop.hidden) {
        return null;
      }

      // Only show pages with layout /dashboard or under the Admin/Product collapses
      if ((prop.collapse && prop.name !== 'Admin' && prop.name !== 'Products') || (!prop.collapse && prop.layout !== '/dashboard')) {
        return null;
      }

      // Check to make sure the user is allowed to see this link
      if (prop.allowedRoles && !prop.allowedRoles.includes(userRole)) {
        return null;
      }

      if (prop.collapse) {
        const st = {};
        st[prop.state] = !this.state[prop.state];
        const navLinkClasses = `${classes.itemLink
        } ${classes.collapseHeaderLink} ${
          cx({
            [` ${classes.collapseActive}`]: this.getCollapseInitialState(prop.views),
            [` ${classes.collapseHeaderLinkAlwaysOpen}`]: prop.collapseAlwaysOpen,
          })}`;
        const itemText = `${classes.itemText
        } ${
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
          })}`;
        const collapseItemText = `${classes.collapseItemText
        } ${
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
          })}`;

        // Add a vertical spacer for the products dropdown
        return (
          <React.Fragment key={key}>
            <div className={classes.collapseSpacer} />
            <ListItem
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined },
              )}
            >
              <NavLink
                to="#"
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault();
                  if (!prop.collapseAlwaysOpen) this.setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                    <Icon className={classes.itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={classes.itemIcon} />
                  )
                ) : (
                  <span className={classes.collapseItemMini}>
                    {prop.mini}
                  </span>
                )}
                <ListItemText
                  primary={prop.name}
                  secondary={!prop.collapseAlwaysOpen ? (
                    <b
                      className={
                      `${classes.caret} ${
                        this.state[prop.state] ? classes.caretActive : ''}`
                    }
                    />
                  ) : null}
                  disableTypography
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined },
                  )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={`${classes.list} ${classes.collapseList}`}>
                  {this.createLinks(prop.views)}
                </List>
              </Collapse>
            </ListItem>
            <div className={classes.collapseSpacer} />
          </React.Fragment>
        );
      }
      const innerNavLinkClasses = `${classes.collapseItemLink
      } ${
        cx({
          [classes.activeRoute]: this.activeRoute(prop.path),
        })}`;
      const collapseItemMini = `${classes.collapseItemMini}`;
      const navLinkClasses = `${classes.itemLink
      } ${
        cx({
          [classes.activeRoute]: this.activeRoute(prop.path),
        })}`;
      const itemText = `${classes.itemText
      } ${
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`;
      const collapseItemText = `${classes.collapseItemText
      } ${
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`;

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={classes.itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={classes.itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {prop.mini}
              </span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined },
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  render() {
    const {
      classes,
      routes,
      userName,
      userRole,
      isAssumedUser,
      onExitAssumedUser,
      isMobileWidth,
    } = this.props;

    const { userInfo } = this.context;

    const handleSettingsOpen = (event) => {
      // If popper is open and the settings gear is clicked, then this gets called on mobile
      // Add this check in to prevent this
      if (this.state.openAvatar) return;

      this.settingsPopperAnchorEl = event.currentTarget;
      this.openCollapse('openAvatar');
    };

    const handleSettingsClose = (event) => {
      // Needed if button navigates to other page by the time this callback is called
      if (event) event.preventDefault();
      this.openCollapse('openAvatar');
    };

    const userItem = (
      <div>
        {(userRole === 'Admin' || userRole === 'Support') && (
          <h5 className={classes.roleInfo}>{`Logged in as ${userRole}`}</h5>
        )}
        {isAssumedUser && (
          <h5 className={classes.isAssumedRoleInfo}>
            IMPERSONATING USER
            <br />
            <Button onClick={onExitAssumedUser}>
              EXIT
            </Button>
          </h5>
        )}
        <div className={classes.userContainer}>
          <div className={classes.userAvatar}>
            {userInfo && userInfo.agentInfo && userInfo.agentInfo.agentPictureUrl
              ? (<img src={userInfo.agentInfo.agentPictureUrl} className={classes.userAvatarImage} alt="..." />)
              : (<AccountCircleIcon fontSize="large" />)}
          </div>
          <div className={classes.userNameText}>
            {userName}
          </div>
          <ButtonBase onClick={handleSettingsOpen}>
            <SettingsIcon className={classes.settingGear} />
          </ButtonBase>
        </div>
      </div>
    );

    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal = `${classes.logoNormal
    } ${
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
      })}`;

    const brand = (
      <div className={classes.logo}>
        <Link
          to="/dashboard"
          className={logoNormal}
        >
          <img src={logo} alt="Harvist" className={classes.img} />
        </Link>
      </div>
    );

    const drawerPaper = `${classes.drawerPaper
    } ${
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      })}`;

    const sidebarWrapper = `${classes.sidebarWrapper
    } ${
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      })}`;

    return (
      <div ref={this.mainPanel}>
        <Popper
          open={this.state.openAvatar}
          // placement="top-start"
          placement={isMobileWidth ? 'top-end' : 'top-start'}
          anchorEl={this.settingsPopperAnchorEl}
          className={classes.settingsPopper}
        >
          <UserSettingsPopUp onClose={handleSettingsClose} />
        </Popper>

        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            classes={{
              paper: `${drawerPaper}`,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userItem}
              // headerLinks={<AdminNavbarLinks />}
              links={links}
            />
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${cx({ [classes.drawerPaperAnnouncement]: this.props.announcementVisible })}`,
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userItem}
              links={links}
            />
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.contextType = UserInfoContext;

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  announcementVisible: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  onExitAssumedUser: PropTypes.func.isRequired,
  isMobileWidth: PropTypes.bool.isRequired,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(({ ...rest }) => {
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const { user, onExitAssumedUser } = useAuthDataContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  // TODO: SHOULD CLEAR DATA  ON LOGOUT/EXIT ASSUMED USER
  // Check added in for the assume user feature. When assuming a user we must force the user info to reload to
  // load the new user's data.
  React.useEffect(() => {
    if (user && userInfo && user.email !== userInfo.email) {
      reloadUserInfo();
    }
  }, [user]);

  return <Sidebar {...rest} onExitAssumedUser={onExitAssumedUser} isMobileWidth={isMobileWidth} />;
});
