import React from 'react';

import {
  Box, ButtonBase, Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 50,
    zIndex: 1200,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.purple.dark,
    padding: '8px 16px',

    [theme.breakpoints.down('sm')]: {
      padding: '8px 24px',
      height: 80,
    },
  },
  content: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.purple.dark,
    textAlign: 'center',

    '& a': {
      color: 'inherit',
    },
  },
}));

interface Props {
  visible: boolean;
  content: React.ReactNode;
  onClose: () => void;
}

export default ({ visible, content, onClose }: Props) => {
  const classes = useStyles();

  if (!visible) return null;

  return (
    <Paper className={classes.root} elevation={3} square>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Box flex={1} className={classes.content}>
          {content}
        </Box>

        <ButtonBase
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </ButtonBase>
      </Box>
    </Paper>
  );
};
