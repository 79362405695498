import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Box, ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { doughnutCenteredTextPlugin, doughnutRoundedDataPlugin } from 'utils/chart-plugins';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({

}));

export interface Props {
  campaignStartDate: Date;
  campaignEndDate: Date;
  onViewTouchTimeline: () => void;
}

export default ({ campaignStartDate, campaignEndDate, onViewTouchTimeline }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const start = moment(campaignStartDate);
  const end = moment(campaignEndDate);

  let percentage = ((moment().unix() - start.unix()) / (end.unix() - start.unix())) * 100;

  // Clamp percentage between 0 and 100
  percentage = Math.max(0, Math.min(100, Math.floor(percentage)));

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    events: [], // Disable all events
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        centeredText: `${percentage}%`,
        roundedCornersFor: {
          start: 0,
          end: 0,
        },
      },
    },
  };

  const chartData = {
    datasets: [{
      cutout: '80%',
      borderWidth: 0,
      data: [percentage, 100 - percentage],
      backgroundColor: ['#56917F', '#D3D3D3'],
    }],
  };

  return (
    <Box sx={{
      height: 317,
      width: '100%',
      // maxWidth: 385,
      flex: 1,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 8,
      bgcolor: theme.palette.offWhite.main,
    }}
    >
      <Box>
        <Box sx={{
          fontWeight: 500,
          fontSize: 21,
          color: theme.palette.purple.dark,
          marginBottom: 15,
        }}
        >
          Campaign Progress
        </Box>

        <ButtonBase onClick={onViewTouchTimeline} style={{ color: theme.palette.primary.main }}>
          View Timeline
          <ChevronRightIcon />
        </ButtonBase>
      </Box>

      <Box sx={{
        padding: 30,
        flex: 1,
        display: 'flex',
      }}
      >
        <Doughnut
          options={options}
          data={chartData}
          plugins={[doughnutRoundedDataPlugin, doughnutCenteredTextPlugin]}
        />
      </Box>
    </Box>
  );
};
