import {
  boxShadow,
  drawerMiniWidth,
  drawerWidth,
  grayColor,
  hexToRgb,
  transition,
} from 'assets/jss/material-dashboard-pro-react';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 1032,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    // overflow: 'auto',
    // ...boxShadow,
    boxShadow: 'inset -1px 0px 0px #EAEAEB',
    width: drawerWidth,

    '&:after': {
      background: '#F7F8FA',
    },

    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'flex',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: 1032,
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },

    '&:before,&:after': {
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`,
  },
  drawerPaperAnnouncement: {
    marginTop: 50,

    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 50px)',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
    },
  },
  logo: {
    padding: '15px 0px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: 4,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
  logoNormal: {
    fontWeight: '600',
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0px, 0, 0)',
    textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    overflow: 'hidden',
    '&,&:hover': {
      color: 'inherit',
    },
  },
  logoNormalSidebarMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)',
  },
  img: {
    padding: '10px 30px',
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  list: {
    marginTop: '15px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
    fontWeight: 500,
  },
  itemLink: {
    paddingLeft: '10px',
    paddingRight: '10px',
    // transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '8px',
    backgroundColor: theme.palette.white.main,
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    width: 'auto',
    color: theme.palette.grayScale11.main,

    '&:hover,&:focus': {
      fontWeight: 600,
      color: theme.palette.purple.dark,
      backgroundColor: `rgba(${hexToRgb(theme.palette.primary.light)}, 0.5)`,
    },
  },
  itemIcon: {
    color: 'inherit',
    width: '30px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: '0.8',
  },
  itemText: {
    color: 'inherit',
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    padding: '0 16px !important',
  },

  userContainer: {
    margin: '24px 24px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userAvatar: {
    transition: 'all 300ms linear',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    display: 'flex',
    '& > svg': {
      margin: 'auto',
    },
  },
  userAvatarImage: {
    width: '40px',
    height: '40px',
    verticalAlign: 'middle',
    borderRadius: '100%',
    objectFit: 'cover',
  },
  userNameText: {
    marginLeft: '16px',
    marginRight: '16px',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  settingGear: {
    transition: 'all 150ms ease-in',
    color: theme.palette.lightGray.main,
  },
  settingsPopper: {
    zIndex: 2000,
    marginBottom: '10px',
  },

  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  collapseList: {
    marginTop: '0',
    '& $caret': {
      marginTop: '8px',
    },
  },
  collapseHeaderLink: {
    color: theme.palette.grayScale11.main,
    backgroundColor: 'transparent',

    '&:hover,&:focus': {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  collapseHeaderLinkAlwaysOpen: {
    cursor: 'default',
    color: 'theme.palette.grayScale11.main !important',
    backgroundColor: 'transparent !important',
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: '8px',
    position: 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    fontWeight: '600',
    width: 'auto',
  },
  collapseItemMini: {
    color: 'inherit',
    fontWeight: '600',
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemText: {
    color: 'inherit',
    fontWeight: '600',
    margin: '0',
    lineHeight: '30px',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '18px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  activeRoute: {
    '&,&:hover': {
      fontWeight: 600,
      color: theme.palette.purple.dark,
      backgroundColor: theme.palette.primary.light,
    },
  },
  sidebarWrapper: {
    position: 'relative',
    flex: 1,
    overflow: 'auto',
    width: drawerWidth,
    zIndex: 4,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '24px',

    display: 'flex',
    flexDirection: 'column',
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  isAssumedRoleInfo: {
    margin: '20px 0 0',
    backgroundColor: `rgba(${hexToRgb('#f44336')}, 0.4)`,
    padding: '6px 15px',
    fontWeight: 500,
    textAlign: 'center',

    '& > button': {
      margin: '4px 0 0',
      backgroundColor: `rgba(${hexToRgb('#f44336')}, 0.6)`,
      fontSize: '12px',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  roleInfo: {
    margin: '20px 0 0',
    backgroundColor: `rgba(${hexToRgb('#ff9800')}, 0.8)`,
    padding: '6px 15px',
    fontWeight: 500,
    textAlign: 'center',
  },
  collapseSpacer: {
    height: '24px',
  },
});

export default sidebarStyle;
