import moment from 'moment';
import React from 'react';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoIcon from '@material-ui/icons/Info';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import ActionModal from 'components/ActionModal/ActionModal';
import AgentInfoModal from 'components/AgentsTable/AgentInfoModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { authenticateAssumeUser, deleteUser } from 'utils/api';
import { enumToReadableName, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
}));

export default function AgentsTable({
  agents, campaigns, setAgents, setOpenModal,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { onLogin } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [isConfirmDelete, setIsConfirmDelete] = React.useState(null);

  const [data, setData] = React.useState([]);

  const onDeleteAccount = async () => {
    const user = isConfirmDelete;

    showLoadingIndicatorModal();

    try {
      await deleteUser(user._id);

      // Remove the deleted agent from our cached agents list
      setAgents(agents.filter(({ _id }) => _id !== user._id));

      setCurrentAlert('success', `${isConfirmDelete.firstName}'s account has been deleted.`);

      setIsConfirmDelete(null);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setCurrentAlert('error', error.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', error);
    }

    hideLoadingIndicatorModal();
  };

  const onAssumeUser = async (userId) => {
    showLoadingIndicatorModal();
    try {
      const { token, expiresInDays } = await authenticateAssumeUser(userId);

      // Call the login function on the auth context
      onLogin(token, expiresInDays);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  React.useEffect(() => {
    if (!agents) return;
    // if (!agents || !campaigns) return;

    // Only show unregistered or active agents
    const activeAgents = agents.filter((o) => o.status === 'UNREGISTERED' || o.status === 'ACTIVE');

    setData(activeAgents.map((user) => {
      /* let createCampaignTooltip;

      if (agent.agentInfo.signupWizardCompleted) {
        createCampaignTooltip = (
          <Tooltip
            title="Create New Campaign"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => history.push(`/dashboard/admin/create-campaign/${agent._id}`)}
              color="success"
            >
              <FiberNewIcon />
            </Button>
          </Tooltip>
        );
      } else {
        // Agent hasn't completed the signup wizard yet
        createCampaignTooltip = null;
      }

      const agentIsSigningUp = !agent.agentInfo.signupWizardCompleted; */

      const a = 1;

      return ({
        brokerage: user.role.name === 'Brokerage Agent' ? user.agentInfo.brokerage.name : '-',
        name: `${user.firstName} ${user.lastName ?? ''}`,
        email: user.email,
        status: enumToReadableName(user.status),
        // status: agentIsSigningUp ? 'SIGNING_UP' : agent.status,
        createdAt: moment(user.createdAt).toDate(),
        actions: (
          <div>
            {/* {createCampaignTooltip} */}
            {user.status === 'ACTIVE' && (
              <Button
                onClick={() => onAssumeUser(user._id)}
                color="orange"
                round
                size="sm"
              >
                Impersonate
              </Button>
            )}

            <Tooltip
              title="Info"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setOpenModal((
                    <AgentInfoModal
                      user={user}
                      // agentCampaigns={campaigns.filter((c) => c.agent._id === user._id)}
                      setOpenModal={setOpenModal}
                    />
                  ));
                }}
                color="dark"
              >
                <InfoIcon />
              </Button>
            </Tooltip>

            {user.status === 'UNREGISTERED' && (
              <Tooltip
                title="Delete Account"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => setIsConfirmDelete(user)}
                  color="orange"
                >
                  <DeleteRoundedIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        ),
      });
    }));
  }, [agents]);
  // }, [agents, campaigns]);

  // if (!agents || !campaigns) {
  if (!agents) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {isConfirmDelete && (
        <ActionModal
          backgroundColor={theme.palette.offWhite.main}
          textColor={theme.palette.darkGray.main}
          icon={<DeleteRoundedIcon />}
          onClose={() => setIsConfirmDelete(null)}
          title="Are You Sure?"
          message={`Are you sure you want to delete ${isConfirmDelete.firstName}'s account?`}
          buttons={[
            <ButtonBase onClick={() => setIsConfirmDelete(null)} className={classes.cancelButton}>No</ButtonBase>,
            <ButtonBase onClick={onDeleteAccount}>Yes</ButtonBase>,
          ]}
        />
      )}

      <ReactTable
        columns={[
          {
            Header: 'Brokerage',
            accessor: 'brokerage',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Agent Status',
            accessor: 'status',
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
            sortType: 'datetime',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          },
        ]}
        data={data}
      />
    </>
  );
}
