import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import ActionModal from 'components/ActionModal/ActionModal';

const useStyles = makeStyles((theme) => ({
  laterButton: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
}));

type Props = {
  onClose: () => void;
  message?: React.ReactNode;
};

const MarketingProfileIncompleteModal = ({ onClose, message }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  return (
    <ActionModal
      backgroundColor={theme.palette.offWhite.main}
      textColor={theme.palette.darkGray.main}
      icon={<WarningRoundedIcon />}
      onClose={onClose}
      title="Complete Your Marketing Profile"
      message={message ?? (
        <div>
          You must complete your marketing profile before your campaign touches can be sent out.
          <br />
          <br />
          If you don't do this quick step, we won't be able to send out any touches for you!
        </div>
      )}
      buttons={[
        <ButtonBase
          onClick={onClose}
          className={classes.laterButton}
        >
          I'll Do This Later
        </ButtonBase>,
        <ButtonBase
          onClick={() => history.push('/dashboard/marketing-profile')}
        >
          Go to Marketing Profile
        </ButtonBase>,
      ]}
    />
  );
};

export default MarketingProfileIncompleteModal;
