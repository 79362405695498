import cx from 'classnames';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 32px 16px 16px',
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.grayScale10.main}`,
    boxShadow: '0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03)',
    borderRadius: '8px',

    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '16px',
    },
    '& > p': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.grayScale9.main,

      '& > button': {
        font: 'inherit',
        verticalAlign: 'top', // Line up with text
        textDecoration: 'underline',
        color: theme.palette.orange.main,

        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

const DashboardBubble = ({
  rootClass, backgroundColor, icon, content,
}) => {
  const classes = useStyles();

  return (
    <span className={cx(classes.root, rootClass)} style={{ backgroundColor }}>
      {icon}
      <p>
        {content}
      </p>
    </span>
  );
};

export default DashboardBubble;
